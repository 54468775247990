<template>
  <v-btn
    @click="changePaymentStatus"
    color="primary"
    small
  >
    {{ 'ec.action.update_payment_status'| t }}
  </v-btn>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formKey: String,
  },
  methods: {
    changePaymentStatus() {
      this.$apopup.base({
        title: this.$t('ec.action.update_payment_status'),
        originPaymentStatus: this.originPaymentStatus,
        bodySlot: () => import('./changePaymentStatusPopup.vue'),
        disabledApply: data => {
          if(!data) return true
          return data.status == this.originPaymentStatus
        },
        applyCallback: this.updatePaymentStatus,
      })
    },
    async updatePaymentStatus(params) {
      try {
        await this.$api.collection.paymentApi.updateStatus(this.orderId, params)
        this.$snotify.success(null, this.$t('save.successfully'))
      } catch (error) {
        console.error(error)
        this.$snotify.error(this.$t('error.unexpect'), this.$t('save.failure'))
      } finally {
        this.formActions.initForm()
      }
    },
  },
  computed: {
    formActions() {
      return this.$store.getters[`form/${this.formKey}/actions`]
    },
    orderId() {
      if(!this.formData) return null
      return window.eagleLodash.get(this.formData, 'id')
    },
    originPaymentStatus() {
      if(!this.formData) return null
      return window.eagleLodash.get(this.formData, 'payment_status')
    },
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`]
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
